<template>
  <!-- Footer -->
  <footer class="footer">
    <!-- Footer Top -->
    <div class="footer-top aos" data-aos="fade-up">
      <div class="container">
        <div class="row">
          <div class="col-lg-2">

                <!-- Footer Widget -->
                <div class="footer-widget footer-menu">
                  <h5 class="footer-title">About Company</h5>
                  <ul>
                    <li>
                      <a href="/pages/terms-condition">Terms and Conditions</a>
                    </li>
                    <li>
                      <a href="/pages/refund-policy">Refund Policy</a>
                    </li>

                  </ul>
                </div>
                <!-- /Footer Widget -->


          </div>
          <div class="col-lg-2">
            <div class="footer-widget footer-menu">
              <h5 class="footer-title">Boats</h5>
              <ul>
                <li>
                  <a href="/listing/listing-details2">Valory 525</a>
                </li>
                <li>
                  <a href="/listing/listing-details1">Valory 480</a>
                </li>
                <li>
                  <a href="/listing/listing-details3">Cobra 495</a>
                </li>
                <li>
                  <a href="/listing/listing-details4">Combo</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="footer-contact footer-widget">
              <h5 class="footer-title">Contact Info</h5>
              <div class="footer-contact-info">
                <div class="footer-address">
                  <span><i class="feather-phone-call"></i></span>
                  <div class="addr-info">
                    <a href="tel:+30698824444">+ 30 698 882 4444</a>
                  </div>
                </div>
                <div class="footer-address">
                  <span><i class="feather-mail"></i></span>
                  <div class="addr-info">
                    <a href="mailto:blackanchor.boats@gmail.com">blackanchor.boats@gmail.com</a>
                  </div>
                </div>

              </div>
              <div class="footer-social-widget">
                <h6>Connect with us</h6>
                <ul class="nav-social">
                  <li>
                    <a href="https://www.facebook.com/profile.php?id=61557044811286" target="_blank"
                    ><i class="fa-brands fa-facebook-f fa-facebook fi-icon"></i
                    ></a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/blackanchor_rent_a_boat?fbclid=IwAR0gx3YdlfmAqKWATYO1S4earOez1J7exUXD1BpjXGfXG85kYZJ1ygjmRIA_aem_AQV5tBISzvYRJ2y3QpkC_xlxCide0gaI0JmuI2lQU0J3zx5K1221Iq2V2CwOrnpEKjldtkOQApfuDgNtUNGhIsNQ" target="_blank"
                    ><i class="fab fa-instagram fi-icon"></i
                    ></a>
                  </li>
                  <li>
                    <a href="https://www.tiktok.com/@black.anchor.boat" target="_blank"
                    ><i class="fab fa-tiktok fi-icon"></i>
                    </a>
                  </li>
                </ul>
              </div>
<a href='https://www.kayak.co.uk' target='_blank'><img height='40' src='https://content.r9cdn.net/rimg/seo/badges/v1/LIGHT_LARGE_LOGO_KAYAK.png.png' /></a>	            </div>
          </div>
          <div class="col-lg-4">
            <div class="footer-contact footer-widget">
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13019.276548741527!2d25.3006875!3d35.3353125!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xb9307295d679949%3A0x38ccec7019de8287!2sBlack%20Anchor%20Boat%20Rentals!5e0!3m2!1sen!2sgr!4v1713383135724!5m2!1sen!2sgr" width="400" height="300" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Footer Top -->

    <!-- Footer Bottom -->
    <div class="footer-bottom">
      <div class="container">
        <!-- Copyright -->
        <div class="copyright">
          <div class="row align-items-center">
            <div class="col-md-6">
              <div class="copyright-text">
                <p>© {{ new Date().getFullYear() }} Black Anchor. All Rights Reserved.</p>
              </div>
            </div>
            <div class="col-md-6">
              <!-- Copyright Menu -->
              <div class="copyright-menu">
                <div class="vistors-details">
                  <ul class="d-flex">
                    <li>
                      <a href="javascript:void(0)"
                        ><img
                          class="img-fluid"
                          src="@/assets/img/icons/visa.svg"
                          alt="Visa"
                      /></a>
                    </li>
                    <li>
                      <a href="javascript:void(0)"
                        ><img
                          class="img-fluid"
                          src="@/assets/img/icons/master.svg"
                          alt="Master"
                      /></a>
                    </li>
                    <li>
                      <a href="javascript:void(0)"
                        ><img
                          class="img-fluid"
                          src="@/assets/img/icons/applegpay.svg"
                          alt="applegpay"
                      /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- /Copyright Menu -->
            </div>
          </div>
        </div>
        <!-- /Copyright -->
      </div>
    </div>
    <!-- /Footer Bottom -->
  </footer>
  <!-- /Footer -->
</template>
<script setup lang="ts">
</script>
